import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Pages/index';
import About from "./Components/about"
import Services from "./Components/services"
import Work from './Components/work';


import Contact from './Pages/contact'
import ContactCSS from './Contact.css'
function Routers () {
    return (
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/about" exact element={<About/>}/>
          <Route path="/service" exact element={<Services/>}/>
          <Route path="/Work" exact element={<Work/>}/>
          <Route path="/contact" cssModule={ContactCSS} exact element={<Contact/>}/>
      </Routes>
  </Router>
    )
}
  

export default Routers;