
import React, { useState, useRef } from "react";
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import Footerpage from '../Components/footer';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from "../Components/navbar"



function Contact() {
  const ConctactForm = useRef();

const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, name]);
     

    } else {
      setCheckedItems(checkedItems.filter((item) => item !== name));
    }
  };

    window.addEventListener('load', function(){
      let fixed =  document.getElementById('alterial');
      fixed.classList.remove('fixed')
    })

    document.addEventListener('DOMContentLoaded', () => {
        const interBubble = document.querySelector('.interactive');
        let curX = 0;
        let curY = 0;
        let tgX = 0;
        let tgY = 0;
    
        function move() {
            curX += (tgX - curX) / 20;
            curY += (tgY - curY) / 20;
            interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
            requestAnimationFrame(() => {
                move();
            });
        }
    
        window.addEventListener('mousemove', (event) => {
            tgX = event.clientX;
            tgY = event.clientY;
        });
    
        move();
    });



    const SendEmail = (e) => {
      e.preventDefault();

       
      emailjs.sendForm('service_233u67x', 'template_bgyeu47', ConctactForm.current,  'Zwij4yGm8To931bW7')
      
        .then((result) => {
            toast.success("Hi Your Message Successfully! Our team will contact you soon.");
            console.log(result.text)
        }, (error) => {
       
            toast.error("Some technical error occurred. Please Contact with our Team.");
            console.log(error)
        });
    };

    
  return (
    <section className='contianer-contact footer '>
      <Nav/>
<a href='/' aria-label="logo" className='alter-logo'></a>
      <div class="gradient-bg">
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
    <div class="gradients-container">
      <div class="g1"></div>
      <div class="g2"></div>
      <div class="g3"></div>
      <div class="g4"></div>
      <div class="g5"></div>
      <div class="interactive"></div>
    </div>
  </div>
   

          <Container>
          <div className='h5tag'>
            <span className='stickyhead'>
            <h4 className='h5tags'><b>Hi</b> Get in Touch</h4>
             <h5> Let Start Your Project with Altair </h5>
            </span>
             <p>Reach out Altair anytime, anywhere – our dedicated team is here to assist you every step of the way.</p>
            </div>
              <div className='Hero_container'>
                  <Col className='Hero_container_div'>
                  <div className="Hero_heading">
                      <h1>Reach & Get In Touch With Us! </h1>
                      <p>Get in touch with us today – our dedicated team is here to help with any questions or concerns..</p>
                  </div>
                  <div className="Hero_righ">
                      <span className="FadeIn_container" data-fill="false">
                          <div className="Hero_rightInner">
                              <a className="Hero_contactLink" href="mailto:info@altairads.com"><svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
  <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
  <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
</svg> info@altairads.com</a>
                              <a className="Hero_contactLink phone" href="tel:+974 44 418 617"><svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg> +974 44 418 617</a>
                              <a className="Hero_contactLink phone" href="tel:+974 44 418 617"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
</svg>
 +974 7006 1665</a>
                              <a className="Hero_contactLink ponter-envent-none location-container" href="/contact">
                                {/* <i class="bi bi-geo-alt-fill"></i> */}
                              {/* <div class="wpb_map_wraper">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.6104884142687!2d51.52390450000001!3d25.250041099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45cf5facc701ad%3A0x4a1aee705f9a532b!2saltair!5e0!3m2!1sen!2sin!4v1681415357431!5m2!1sen!2sin"
                                        width="600" height="450" allowfullscreen="" loading="lazy"  referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div> */}
                                 <div className='locationh1'>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg> Doha, Qatar
                                  <p> Street no. 330, Rawdat Al Khail Building no: 210, Area No.43 Nuaija, Doha</p>
                                 </div>
                              </a>
                              {/* <a className="Hero_contactLink ponter-envent-none"  href="/contact"><i class="bi bi-geo-alt-fill"></i>
                                  Middlesex, UK
                                  <p>4 Fairacres, Ruislip, Middlesex England, HA49AN</p>
                              </a> */}
                          </div>
                      </span>
                  </div>
                  </Col>
                  <div className="Form_contactForm">
                      <div className="Form_formOuter__NHTHq">
                          <div className="FadeIn_container">
                              <form className="Form_form" ref={ConctactForm}  onSubmit={SendEmail}>
                                  <fieldset className="Form_fullWidth" data-gtm-form-interact-id={checkedItems} >
                                    
                                      <legend>I am interested in :</legend>
                                      <label className={checkedItems.includes("Digital marketing") ? 'active' : ''}><input onChange={handleCheckboxChange} checked={checkedItems.includes("Digital marketing")}  type="checkbox" name="Digital marketing" value="Digital marketing"/><span className="Form_button">Digital marketing</span></label>
                                      <label className={checkedItems.includes("Social Media Management") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Social Media Management")}  type="checkbox" name="Social Media Management" /><span className="Form_button">Social Media Management</span></label>
                                      <label className={checkedItems.includes("Brand Activation") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Brand Activation")}  type="checkbox" name="Brand Activation" /><span className="Form_button">Brand Activation</span></label>
                                      <label className={checkedItems.includes("Printing & Advertising") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Printing & Advertising")}  type="checkbox" name="Printing & Advertising" /><span className="Form_button">Printing & Advertising</span></label>
                                      <label className={checkedItems.includes("Events") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Events")}  type="checkbox" name="Events" /><span className="Form_button">Events</span></label>
                                      <label className={checkedItems.includes("creative Campaigns") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("creative Campaigns")}  type="checkbox" name="creative Campaigns" /><span className="Form_button">creative Campaigns</span></label>
                                      <label className={checkedItems.includes("marketing Support") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("marketing Support")}  type="checkbox" name="marketing Support" /><span className="Form_button">marketing Support</span></label>
                                      <label className={checkedItems.includes("Brand identity & positioning") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Brand identity & positioning")}  type="checkbox" name="Brand identity & positioning" /><span className="Form_button">Brand identity & positioning</span></label>
                                      <label className={checkedItems.includes("Web Design & Development") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Web Design & Development")}  type="checkbox" name="Web Design & Development" /><span className="Form_button">Web Design & Development</span></label>
                                      <label className={checkedItems.includes("Copy Writing") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("Copy Writing")}  type="checkbox" name="Copy Writing" /><span className="Form_button">Copy Writing</span></label>
                                      <label className={checkedItems.includes("E-Commerce") ? 'active' : ''}><input  className='checkBox' onChange={handleCheckboxChange} checked={checkedItems.includes("E-Commerce")}  type="checkbox" name="E-Commerce" /><span className="Form_button">E-Commerce</span></label>

                                  </fieldset>
                                  <input className="inputBox Form_quarterWidth" placeholder="Name*" required="" type="text" name="name" />
                                  <input className="inputBox Form_quarterWidth" placeholder="Mobile No*" required="" type="text" name="mobile" />
                                  <input className="inputBox Form_halfWidth" placeholder="Email*" required="" type="email" name="email" />
                                  <input className="inputBox Form_halfWidth" placeholder="Company Name" type="text" name="company" />
                                  <input name="service" className="inputopcity" value={checkedItems}/>
                                  <textarea className="inputBox Form_fullWidth" placeholder="Message" type="text" name="message" />
                                  <div className="Form_bottomRow"> 
                                      <button type="submit" aria-disabled="false" className="Form_submit "><span>Submit
                                          </span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" className="Form_buttonArrow">
                                              <path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path>
                                          </svg></button>
                                         
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </Container>
          <ToastContainer />
          <div className="landingcontact">
                
   
                <div className='cont-details'>
                     <div className='_socilamedia'>
                     <div className='cont-details_1'>
                     <ul className='listPage'>
                                <li className='menuFooter'> <a href="/">Home <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/about">About <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/service">Services <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/work">Work <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                             </ul>
                   </div>
                   <div className='cont-details_2'>
                       
                       <div className='socoilmedia'>
                             <Footerpage/>
                         </div>
                   </div>
                     </div>
                    
                </div>
           
            </div>
                                           {/* <li className='menuFooter' href="/clients" eventKey="clients">Clients <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></li>
                                <li className='menuFooter' href="#teams" eventKey="teams">Teams <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></li> */}
  </section>
   
  );
}

export default Contact;