import React from "react";
// import { Container } from "react-bootstrap";
// import  ScrollTrigger  from 'gsap/ScrollTrigger';
// import { MotionAnimate } from 'react-motion-animate'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
// import SplitType from 'split-type'
// import Videos from '../assets/homepage-3.mp4'
import 'swiper/css';
import 'swiper/css/pagination';
import Avatar from "../assets/altairads-Logo2024.png";
import ContactDetails from './Stickyfooter'
import Nav from "../Components/navbar"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import services_0 from "../assets/services/service_01.webp"
import services_1 from "../assets/services/service_02.webp"
import services_2 from "../assets/services/service_03.webp"
import services_3 from "../assets/services/service_04.webp"
import services_4 from "../assets/services/service_05.webp"
// import { Container } from 'react-bootstrap';
// import { Parallax, ParallaxLayer } from '@react-spring/parallax'

function Containerservices() {
  


    useGSAP(() => {

        gsap.config({
            nullTargetWarn: false,
            trialWarn: false,
            });
         
            
        

        
        let  b1 = "linear-gradient(217deg, rgb(201 0 130), rgba(255,0,0,0) 70.71%),  linear-gradient(127deg, rgb(33 14 117), rgba(0,255,0,0) 70.71%), linear-gradient(336deg, rgb(201 0 130), rgba(0,0,255,0) 70.71%)";
        let  b2 = "linear-gradient(17deg, rgba(255,0,0,.7), rgba(255,0,0,0) 70.71%), linear-gradient(200deg, rgb(33 14 117), rgb(58 10 95) 70.71%),  linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0.1) 70.71%)";
        let  b3 = "linear-gradient(17deg, #de52ff, rgb(255 243 43 / 0%) 70.71%), linear-gradient(200deg, rgb(71 18 90), rgb(131 0 194) 70.71%), linear-gradient(336deg, #ffc107, rgba(0, 0, 255, 0.1) 70.71%)";
    
        gsap.fromTo(".wrapper-animation", {width:300, height:200, background: b1},{ease: "ease.in",  width:500, height:500, duration: 4, background: b3, repeat: -1,}, {ease: "none", duration: 4, background: b2, repeat: -1, yoyo: true});
    
        // const InnerHeight = window.innerHeight
            // gsap.registerPlugin(ScrollTrigger);

            let panels = gsap.utils.toArray(".panel-carousel");
         


    //     let mySplitText = new SplitType('.services h3', {
    //         types: "words",
    //         wordClass: "split-chars",
    //         tagName: 'span'
    //       });
    
    //       let typeSplith1 = new SplitType('.Servicesh1', {
    //         types: 'words',
    //         tagName: 'span'
    //       });
    //       console.log(typeSplith1)

          

    //   gsap.from('.ServicesPanel_tag', {duration: .5,rotateX: '90%',  ease: 'power1.inOut', stagger:.5, y: '90%'});
    //   gsap.from('.Servicesh1 .word', { y: '100%', opacity: 0,   repeat:-1,  repeatDelay: 2,  duration: 4.1,ease: 'elastic.out', stagger: 0.8, }) 
         
      
            let tl = gsap.timeline({scrollTrigger: {trigger:".services",start: "top bottom",end: "bottom top",toggleActions: "restart none none none"}});
            tl.from('.split-chars', { y: '100%',opacity: 0, duration: .1,ease: 'back.inOut',stagger: .1,},.5)
            tl.from('.service-tag', {duration: .5,rotateX: '90%',  ease: 'power1.inOut', stagger:.5, y: '90%'},'+=.5');
            panels.forEach((t) => {
                let tn = gsap.timeline({
                    autoAlpha: 0,
               
                    ease: "sine.in",
                    scrollTrigger: {
                        trigger: t,
                        start: ()=>"30% 30%",
                        end: ()=>"center top",
                        scrub: !0,
                        pin: !0,
                        pinSpacing: !1,
                        toggleActions: "play none reverse none"
                    }
                  });
                  tn.to(t, {
                    y:400,
                    opacity: 0,
                    duration: 1,
                    scale: .5,
                    stagger: 1,
                    animation: tn,
                    ease: "sine.in",
                  });

                  

                });



               
            tl.play();
        });
        //   let letteranimation = document.querySelectorAll('.split-chars');
    
         
    
        // const alignCenter = { display: 'flex', alignItems: 'center' }

    return(
       <section className="innerContainer"> 
        
<Nav/>
<a href='/' aria-label="logo" className='alter-logo'></a>
        <div data-scroll-section className='section-contianer panel' id="services">

<div className="sticyLayer">
 <div className='wrapper-animation blur'></div> 

            <div className="services">
        
            <h3>
                We've become trusted partner for businesses looking to navigate Qatar's digital and physical channels.

            
            </h3>
        
               
        <span className="service-tag">Digital marketing</span>
                <span className="service-tag">Social Media Management</span>
                <span className="service-tag">Brand Activation</span>
                <span className="service-tag">Printing & Advertising</span>
                <span className="service-tag">Events</span>
                <span className="service-tag">creative Campaigns</span>
                <span className="service-tag">marketing Support</span>
                <span className="service-tag">Brand identity & positioning</span>
                <span className="service-tag">Web Design & Development</span>
                <span className="service-tag">Copy Writing</span>
                <span className="service-tag">E-Commerce</span>
            
        </div>
      
         <div className="servicestype">
            

          <div className='panel-carousel'>
        {/* <div className='wrapper-animation blur'></div> */}
                     <div className='servive-grid'>
                       
                             <span className='service-info'>
                                 <h3 class="Servicesh1">Digital Marketing</h3>
                                 <div class="ServicesPanel_tags">
                                     <span class="ServicesPanel_tag">Social Media Management</span>
                                     <span class="ServicesPanel_tag">Search Engine Optimization</span>
                                     <span class="ServicesPanel_tag">PPC - Paid Advertising</span>
                                     <span class="ServicesPanel_tag">On - Demand Campaigns</span>
                                     <span class="ServicesPanel_tag">Website Development</span>
                                     <span class="ServicesPanel_tag">Custom Web Applications</span>
                                 </div>
                                 <p class="Services-text text-cont">Our Digital service is a comprehensive solution to enhance
                                     your online presence and drive business growth. We offer a range of services,
                                     including website development, search engine optimization, social media marketing,
                                     and more. Let us help you leverage the power of digital marketing to reach your
                                     target audience and achieve your marketing goals.</p>
                                 <a class="Button_inner" href="/contact">keep in touch <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg> </a>
                             </span>
                         
                         <span className='service-pc'>
                             <span className='animated-video'>
                                 <img src={services_0} alt="services"/>
                             </span>
                         </span>
                     </div>
                 </div>
            
      <div className='panel-carousel'>
             {/* <div className='wrapper-animation blur'></div> */}
                     <div className='servive-grid'>
                       
                             <span className='service-info'>
                                 <h3 class="Servicesh1">Design</h3>
                                 <div class="ServicesPanel_tags">
                                     <span class="ServicesPanel_tag">Branding</span>
                                     <span class="ServicesPanel_tag">Digital Assets</span>
                                     <span class="ServicesPanel_tag">Collateral</span>
                                 </div>
                                 <p class="Services-text">Our Design service provides creative solutions to elevate your
                                     brand identity and engage your audience. From logos to website design, we create
                                     visually appealing and functional designs that align with your brand messaging and
                                     business objectives. Let us help you make a lasting impression with our expert
                                     design services.</p>
                                 <a class="Button_inner" href="/contact">keep in touch <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg> </a>
                             </span>
                         
                         <span className='service-pc'>
                             <span className='animated-video'>
                                  <img src={services_1} alt="services"/>
                             </span>
                         </span>
                     </div>
                 </div>
                
      <div className='panel-carousel'>
             {/* <div className='wrapper-animation blur'></div> */}
                     <div className='servive-grid'>
                       
                             <span className='service-info'>
                                 <h3 class="Servicesh1">Advertising</h3>
                                 <div class="ServicesPanel_tags">
                                     <span class="ServicesPanel_tag">Strategy & Creative</span>
                                     <span class="ServicesPanel_tag">ATL & BTL</span>
                                     <span class="ServicesPanel_tag">Printing</span>
                                 </div>
                                 <p class="Services-text">Our Advertising service helps businesses reach their target
                                     audience effectively by creating and executing successful campaigns on social
                                     media, search engines, display networks, and more. We use data-driven insights to
                                     develop tailored strategies that align with your business objectives and drive
                                     results.</p>
                                 <a class="Button_inner" href="/contact">keep in touch <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg> </a>
                             </span>
                         
                         <span className='service-pc'>
                             <span className='animated-video'>
                                  <img src={services_2} alt="services"/>
                             </span>
                         </span>
                     </div>
                 </div>

                 
      <div className='panel-carousel'>
             {/* <div className='wrapper-animation blur'></div> */}
                     <div className='servive-grid'>
                       
                             <span className='service-info'>
                                 <h3 class="Servicesh1">Event</h3>
                                 <div class="ServicesPanel_tags">
                                     <span class="ServicesPanel_tag">Conference</span>
                                     <span class="ServicesPanel_tag">Product Launch</span>
                                     <span class="ServicesPanel_tag">Festivals & Parties</span>
                                 </div>
                                 <p class="Services-text">Our Event service provides end-to-end solutions for successful
                                     event planning and execution. From corporate events to product launches, we help
                                     businesses create memorable experiences that resonate with their audience. Let us
                                     help you deliver seamless events that leave a lasting impression and drive business
                                     growth.</p>
                                 <a class="Button_inner" href="/contact">keep in touch <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg> </a>
                             </span>
                         
                         <span className='service-pc'>
                             <span className='animated-video'>
                                  <img src={services_3} alt="services"/>
                             </span>
                         </span>
                     </div>
                 </div>
               
      <div className='panel-carousel'>
             {/* <div className='wrapper-animation blur'></div> */}
                     <div className='servive-grid'>
                       
                             <span className='service-info'>
                                 <h3 class="Servicesh1">Gift</h3>
                                 <div class="ServicesPanel_tags">
                                     <span class="ServicesPanel_tag">Corporate Gift</span>
                                 </div>
                                 <p class="Services-text">Our Corporate Gift service helps businesses create lasting
                                     impressions by providing unique and customized gifts for clients, partners, and
                                     employees. From branded merchandise to personalized gifts, we offer a range of
                                     options to suit your needs and budget. Let us help you strengthen your business
                                     relationships through thoughtful corporate gifting.</p>
                                 <a class="Button_inner" href="/contact">keep in touch <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg> </a>
                             </span>
                         
                         <span className='service-pc'>
                             <span className='animated-video'>
                                  <img src={services_4} alt="services"/>
                             </span>
                         </span>
                     </div>
                 </div>
         </div>
        </div>
     
        </div>

<div data-scroll-section className='section-contianer panel' id="contact">
<ContactDetails/>

</div>
<FloatingWhatsApp className='whatsapp-container' avatar={Avatar}  phoneNumber="+974 7006 1665" accountName="Altairads" allowEsc allowClickAway notification notificationSound/>

       </section>
    )
}

export default Containerservices;