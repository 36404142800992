import React, {useState} from 'react'
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
// import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import "swiper/css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Avatar from "../assets/altairads-Logo2024.png";
import ContactDetails from './Stickyfooter'
import Nav from "./navbar"
import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import Modal from 'react-bootstrap/Modal';
// ///Images 
import	Portfolio_0	  from	"../assets/AltairWorks/Aldar/2206fef5-04de-476d-b182-74b698d16a8d.jpeg"
import	Portfolio_1	  from	"../assets/AltairWorks/Aldar/AldarBranchOpen.jpeg"
import	Portfolio_2   from	"../assets/AltairWorks/BinArbaidTransportation/Transportation1.jpg"
import	Portfolio_3   from	"../assets/AltairWorks/BinarbaidFabrication/Fabrication_2.jpg"
import	Portfolio_4	  from	"../assets/AltairWorks/BinarbaidFabrication/Fabrication_1.jpg"
import	Portfolio_5	  from	"../assets/AltairWorks/CreamDream/CreamDream2.jpg"
import	Portfolio_6	  from	"../assets/AltairWorks/DohaClinic/DohaClininc (1).jpg"
import	Portfolio_7	  from	"../assets/AltairWorks/DohaClinic/DohaClininc (3).jpg"
import	Portfolio_8	  from	"../assets/AltairWorks/Gomosafer/GoMosafer (1).jpg"
import	Portfolio_9	  from	"../assets/AltairWorks/Ishaal/Ishaal (2).jpg"
import	Portfolio_10	from	"../assets/AltairWorks/Ishaal/Ishaal (4).jpg"
import	Portfolio_11	from	"../assets/AltairWorks/Ishaal/Ishaal (1).jpg"
import	Portfolio_12	from	"../assets/AltairWorks/JumboSouq/0091a8d0-63b2-4a3b-b9b4-47f720b3048e.jpeg"
import	Portfolio_13	from	"../assets/AltairWorks/JumboSouq/9e3f394d-a0de-4298-be70-0bda84445586.jpeg"
import	Portfolio_14	from	"../assets/AltairWorks/Osis Group/Osis_Brochure1.jpg"
import	Portfolio_15	from	"../assets/AltairWorks/Osis Group/Osis_Brochure2.jpg"
import	Portfolio_16	from	"../assets/AltairWorks/Somerset/caae8be4-9e5c-4efa-943a-cc42b73b329f.jpeg"
import	Portfolio_17	from	"../assets/AltairWorks/Sport Accelerator/SpoprtAccelerator (2).jpg"
import	Portfolio_18	from	"../assets/AltairWorks/Somerset/3e643837-cb14-42ce-a246-6c76a5d72ae7.jpeg"
import	Portfolio_19	from	"../assets/AltairWorks/Sport Accelerator/SpoprtAccelerator (1).jpg"
import	Portfolio_20	from	"../assets/AltairWorks/WaqifSiam/waaqif-siam-02.jpg"


 
// // Company base work 
// Aldar
import work_0 from "../assets/AltairWorks/Aldar/Aldar_GoldenChance.jpeg"
import work_1 from "../assets/AltairWorks/Aldar/RamadanOffer.jpeg"
import work_2 from "../assets/AltairWorks/Aldar/RamadanOffer2.jpeg"
import work_3 from "../assets/AltairWorks/Aldar/Visa.jpeg"
import work_4 from "../assets/AltairWorks/Aldar/Visa2.jpeg"
//BinarbaidFabrication
import BinarbaidFabrication_01 from "../assets/AltairWorks/BinarbaidFabrication/Fabrication_3.jpg"
// CreamDream
import CreamDream_02 from "../assets/AltairWorks/CreamDream/CreamDreamt.jpg"
//DohaClinic
import	DohaClinic_01	  from	"../assets/AltairWorks/DohaClinic/DohaClininc (2).jpg"
// Gomosafer]
import Gomosafer_0 from "../assets/AltairWorks/Gomosafer/GoMosafer (2).jpg"
// Ishaal
import Ishaal_0 from "../assets/AltairWorks/Ishaal/Ishaal (3).jpg"
import Ishaal_1 from "../assets/AltairWorks/Ishaal/Ishaal (5).jpg"
import Ishaal_2 from "../assets/AltairWorks/Ishaal/Ishaal (6).jpg"

// PJumboSouq
import	PJumboSouq_0	from	"../assets/AltairWorks/JumboSouq/394ff7a3-3fee-47e0-8355-c303f363d6ee.jpeg"

// Somerset
import	Somerset_0	from	"../assets/AltairWorks/Somerset/d3613922-7786-4e5e-9cf1-9c1b7c97cedf.jpeg"
import	Somerset_1	from	"../assets/AltairWorks/Somerset/thumbnail.jpeg"

// WaqifSiam
import	WaqifSiam_0	from	"../assets/AltairWorks/WaqifSiam/waaqif-siam-01.jpg"



function Projects() {

  const imageUpload = [
    {
      Id: "0",  
      Name: "Aldar",
         title: "We delivered a unified marketing (online & offline) for a stronger brand & richer customer experience.",
         year: '2024',
       src:Portfolio_0,
       images: [
          Portfolio_0,
          Portfolio_1,
          work_0,
          work_1,
          work_2,
          work_3,
          work_4
       ]
     },
     {
      Id: "1",  
      Name: "Aldar",
         title: "We delivered a unified marketing (online & offline) for a stronger brand & richer customer experience.",
         year: '2024',
       src:Portfolio_1,
       images: [
        Portfolio_0,
          Portfolio_1,
          work_0,
          work_1,
          work_2,
          work_3,
          work_4
     ]
     },
     {
      Id: "2",  
      Name: "BinArbaidTransportation",
         title: "Shifting gears on social media for Bin Arbaid Transportation.",
         year: '2024',
       src:Portfolio_2,
       images: [
        Portfolio_2 
     ]
     },
     {
      Id: "3",  
      Name: "BinarbaidFabrication",
         title: "We craft engaging social media for them to keep you hooked.",
         year: '2024',
       src:Portfolio_3,
       images: [
        Portfolio_3,
        Portfolio_4,
        BinarbaidFabrication_01
     ]
     },
     {
      Id: "4",  
      Name: "BinarbaidFabrication",
         title: "We craft engaging social media for them to keep you hooked.",
         year: '2024',
       src:Portfolio_4,
       images: [
        Portfolio_3,
        Portfolio_4,
        BinarbaidFabrication_01
     ]
     },
     {
      Id: "5",  
      Name: "CreamDream",
         title: "Our brainchild. We built the brand from the ground up.",
         year: '2024',
       src:Portfolio_5,
       images: [
        Portfolio_5,
        CreamDream_02
     ]
     },
     {
      Id: "6",  
      Name: "DohaClinic",
         title: "Healthy conversations on social media!  Amplifying their voice online.",
         year: '2024',
       src:Portfolio_6,
       images: [
        DohaClinic_01,
        Portfolio_6,
        Portfolio_7
     ]
     },
     {
      Id: "7",  
      Name: "DohaClinic",
         title: "Healthy conversations on social media!  Amplifying their voice online.",
         year: '2024',
       src:Portfolio_7,
       images: [
        DohaClinic_01,
        Portfolio_6,
        Portfolio_7
     ]
     },
     {
      Id: "8",  
      Name: "Gomosafer",
         title: "We put the social in Gomosafer's travel adventures.",
         year: '2024',
       src:Portfolio_8,
       images: [
        Portfolio_8,
        Gomosafer_0
     ]
     },
     {
      Id: "9",  
      Name: "Ishaal",
         title: "Elevated image, amplified reach. We brought creative & marketing muscle to their brand.",
         year: '2024',
       src:Portfolio_9,
       images: [
        Portfolio_9,
        Portfolio_10,
        Portfolio_11,
        Ishaal_0,
        Ishaal_1,
        Ishaal_2,
     ]
     },
     {
      Id: "10",  
      Name: "Ishaal",
         title: "Elevated image, amplified reach. We brought creative & marketing muscle to their brand.",
         year: '2024',
       src:Portfolio_10,
       images: [
        Portfolio_9,
        Portfolio_10,
        Portfolio_11,
        Ishaal_0,
        Ishaal_1,
        Ishaal_2,
     ]
     },
     {
      Id: "11",  
      Name: "Ishaal",
         title: "Elevated image, amplified reach. We brought creative & marketing muscle to their brand.",
         year: '2024',
       src:Portfolio_11,
       images: [
        Portfolio_9,
        Portfolio_10,
        Portfolio_11,
        Ishaal_0,
        Ishaal_1,
        Ishaal_2,
     ]
     },
     {
      Id: "12",  
      Name: "JumboSouq",
         title: "More clicks, more sales! Our social media & digital marketing strategies drive traffic and conversions.",
         year: '2024',
       src:Portfolio_12,
       images: [
        Portfolio_12,
        PJumboSouq_0,
        Portfolio_13
     ]
     },
     {
      Id: "13",  
      Name: "JumboSouq",
         title: "More clicks, more sales! Our social media & digital marketing strategies drive traffic and conversions.",
         year: '2024',
       src:Portfolio_13,
       images: [
        Portfolio_12,
        PJumboSouq_0,
        Portfolio_13
     ]
     },
     {
      Id: "14",  
      Name: "Osis Group",
         title: "From Ordinary to Oh-So-Impressive! We transformed their company profile and social media presence.",
         year: '2024',
       src:Portfolio_14,
       images: [
        Portfolio_14,
        Portfolio_15
     ]
     },
     {
      Id: "15",  
      Name: "Osis Group",
         title: "From Ordinary to Oh-So-Impressive! We transformed their company profile and social media presence.",
         year: '2024',
       src:Portfolio_15,
       images: [
        Portfolio_14,
        Portfolio_15
     ]
     },
     {
      Id: "16",  
      Name: "Somerset",
         title: "capturing the essence of their luxurious serviced residences through social media with a strategic approach.",
         year: '2024',
       src:Portfolio_16,
       images: [
        Portfolio_16,
        Portfolio_18,
        Somerset_0,
        Somerset_1
     ]
     },
     {
      Id: "17",  
      Name: "Sport Accelerator",
         title: "Growing their game on social media! Our strategic partnership drives results, connecting them with the right audience.",
         year: '2024',
       src:Portfolio_17,
       images: [
        Portfolio_19,
        Portfolio_17,
        
     ]
     },
     {
      Id: "18",  
      Name: "Somerset",
         title: "capturing the essence of their luxurious serviced residences through social media with a strategic approach.",
         year: '2024',
       src:Portfolio_18,
       images: [
        Portfolio_16,
        Portfolio_18,
        Somerset_0,
        Somerset_1
     ]
     },
     {
      Id: "19",  
      Name: "Sport Accelerator",
         title: "Growing their game on social media! Our strategic partnership drives results, connecting them with the right audience.",
         year: '2024',
       src:Portfolio_19,
       images: [
        Portfolio_19,
        Portfolio_17,
     ]
     },
     {
      Id: "20",  
      Name: "WaqifSiam",
         title: "A Brand Steeped in Tradition! We brought our creative vision to life, crafting a memorable brand identity for Waqif Siam.",
         year: '2024',
       src:Portfolio_20,
       images: [
        Portfolio_20,
        WaqifSiam_0
     ]
     },
    
  ]



  useGSAP(() => {
    let tl = gsap.timeline({scrollTrigger: {trigger:"#Marketing",start: "top bottom",end: "bottom top",toggleActions: "restart none none none"}});
    tl.fromTo(".image_continer", {autoAlpha:0}, {
        autoAlpha: 1, 
        opacity:0,
        duration:.6,
        overwrite: true, 
        stagger: { 
          from: "random", 
          amount: .6, 
          ease: "elastic",
        }
      });
    })



    

  const showModalById = (id) => {
       const modal = document.getElementById(id);
       const overflow = document.getElementById('alterial');
       overflow.classList.add('modal-open')
       modal.classList.add('in')
    };
  



   const ModalClose  = (id) => {
    const modal = document.getElementById(id);
    const overflow = document.getElementById('alterial');
    overflow.classList.remove('modal-open')
    modal.classList.remove('in')
   }
 







    return (

      <section className="innerContainer">
                <Nav/>
                <a href='/' className='alter-logo'></a>
<div data-scroll-section className='section-contianer panel' id="services">
<Container>
  
  <div className='h5tag'>
              <span className='stickyhead'>
              <h4 className='h5tags'><b>Our</b> WORK</h4>
               <h5> A Portfolio of Altair  Work</h5>
              </span>
               <p>
               Welcome to Altair Work, where creativity meets functionality and passion meets purpose. Within these digital walls, you'll find a curated collection of  work, each piece a testament with skills, expertise, and dedication to my craft.
               </p>
              </div>
  {/* <a href="/portfolio" className='back-portfolio'><i class="bi bi-arrow-left"></i></a> */}
    {/* <Nav className="justify-content-end flex-grow-1 pe-3 tab-porfolio" variant="tabs" defaultActiveKey="Marketing">
      <Nav.Link href="#Marketing" eventKey="Marketing">Digital Marketing</Nav.Link>
      <Nav.Link href="#Advertising" eventKey="Advertising">PPC Advertising</Nav.Link>
      <Nav.Link href="#Campaigns" eventKey="Campaigns">BTL Advertising Campaigns</Nav.Link>
      <Nav.Link href="#Animation" eventKey="Animation">2D/3D Animation</Nav.Link>
    </Nav> */}
    <div className="work-section" id="smooth-content">
  
      <div className='gallery' id="Marketing">
      <div class="cards">
      <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 650: 2, 750: 3,  950: 4}}
              >
      <Masonry columnsCount={4} gutter="2em">
                  {imageUpload.map((image, i) => (
                // ' 
                    <div className='image_continer'>
                     <div className='container-img' onClick={() => showModalById(image.Id)}>
                     <div class="ProjectCardText"><h3><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.78 14.78"><defs><linearGradient id="d" x1="3.54" x2="13.98" y1="3.54" y2="13.99" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#7c41f3"></stop><stop offset="1" stop-color="#ff6eff"></stop></linearGradient></defs><path fill="url(#d)" stroke-width="0" d="M13.25 12.18L13.25 0.23 14.78 0.23 14.78 14.76 14.74 14.76 14.74 14.78 0.21 14.78 0.21 13.25 12.18 13.25 0 1.08 1.08 0 13.25 12.18z"></path></svg>
                       {image.Name}</h3></div>
                       <img
                          src={image.src}
                          alt="Altairads works"
                          style={{width: "100%", display: "block"}}
                      />
                     
                      <div class="CardimageOverlay"><p class="Cardheading">{image.title}</p><ul class="ProjectCard_tags__XdVcf"><li>Branding</li><li>Web design &amp; development</li><li>Media &amp; Entertainment</li></ul></div>
                     
                     </div>
                     
                     
                      <div role="dialog" aria-modal="true" id={image.Id}  class="modalContainer modal show" tabindex="-1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div class="modal-title h4">{image.Name}  <i>@ Altairads.com</i>  <a class="FloatingStartButton" href="/contact"><span
                                                    class="FloatingStartButton_text"><span>Start your project</span><svg
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" class="buttonArrow">
                                                        <path
                                                            d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z"
                                                            fill="currentColor"></path>
                                                    </svg></span></a></div>
                                    <button  onClick={() => ModalClose(image.Id)} type="close" class="btn-close" aria-label="Close" fdprocessedid="8g0eh"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="modal-container">
                                        <div class="modal1">
                                            <p class="Cardheading">{image.title}</p>
                                            <ul class="ProjectCard_tags__XdVcf">
                                                <li>Branding</li>
                                                <li>Web design &amp; development</li>
                                                <li>Media &amp; Entertainment</li>
                                            </ul>
                                        </div>
                                        <div class="container-image">
                                        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 650: 2}}>
                                          <Masonry columnsCount={2} gutter="2em">    
                                        {image.images.map((imgs) => (
                                          <img src={imgs} alt="Altairads works"/>
                                        ))}

                                            </Masonry>
                                            </ResponsiveMasonry>   
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    </div>
                     
                  ))}
              </Masonry>
              </ResponsiveMasonry>
  
      </div>
  
      {/* <div id="item02" class="port">
    
      <div class="row">
        <div class="description">
          <h1>Item 02</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis libero erat. Integer ac purus est. Proin erat mi, pulvinar ut magna eget, consectetur auctor turpis.</p>
        </div>
        <img src="https://cdn.dribbble.com/users/545884/screenshots/2883479/cover.jpg" alt=""/>
      </div>
  
    </div> */}
      </div>
    
      {/* <div class="gallery" id="Advertising"></div>
      <div class="gallery" id="Campaigns"></div>
      <div class="gallery" id="Animation"></div> */}
    </div>
    {/* {imageUpload.map((modal, id) => (
                     
                  ))}
   */}
    </Container>
      </div>
      <div data-scroll-section className='section-contianer panel' id="contact">
<ContactDetails/>

</div>
      <FloatingWhatsApp className='whatsapp-container' avatar={Avatar}  phoneNumber="+974 7006 1665" accountName="Altairads" allowEsc allowClickAway notification notificationSound/>
      </section>
      
 
    )
}


export default Projects;