import React from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Avatar from "../assets/altairads-Logo2024.png";
import Lottie from "lottie-react";
import LandParticles from "../assets/LandParticles.json";
import Stickyfooter from "../Components/footer"
import Nav from "../Components/navbar"

import Floatingstars from '../Components/starthree';

function Landingpage() {
    
  

  useGSAP(() => {
   
 

        let animate = gsap.timeline();
        animate.to('.city', {duration:.6,ease: "expo.out",opacity:1,y: -50})
        .to('.nav-menu', {duration:.6,ease: "power1.out",opacity:1,y: -50})
               .to('.spinlogo', {duration:.6,ease: "expo.out",opacity:1,y: -100})
               .to("h1.h1tags", {x: 70, duration:.6, opacity: 1, ease: "power1.out", stagger: 1.25, })
               .to("h2.h1tags", { x: -50, duration:.6, opacity: 1, ease: "power1.out", stagger: 1.25,})
               .to("h3.h1tags", { x: 120, duration:.6, opacity: 1, ease: "power1.out", stagger: 1.25,})
               .to('.theboy', {duration:3,ease: "expo.out",opacity:1,y: -50})
               
               .to('.social-media', {duration:.3,ease: "power1.out",opacity:1,y: -50})
               .to("p.ptags", { x: 70, duration:.6, opacity: 1, ease: "power1.out", stagger: 1.25,})
               .to('.ourhistory', {duration:1,ease: "power1.out",opacity:1,y: -50})
              
           
 

       const items = document.querySelectorAll(".data");

       gsap.from(items, {
       textContent: 0,
       duration: 10,
       ease: "power1.out",
       snap: { textContent: 1 },
       stagger: {
           each: 0.1,
           onUpdate: function() {
           this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
           },
       }
       });


       function numberWithCommas(x) {
       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       }
  
    })
      //  const alignCenter = { display: 'flex', alignItems: 'center' }
   return(
   
<div className='homepage' id="maincontainer">
    <Nav/>
    <a href='/' aria-label="logo" className='alter-logo'></a>
   
    {/*Home */}
    <section data-scroll-section className='container-flict panel'>
        <Floatingstars/>
        <div className='container-home' id="home">
       
            <div className='h1tag'>
                <h1 className='h1tags'><b>Design to</b> Inspire</h1>
                <h2 className='h1tags'>Innovate <b>to engage</b></h2>
                <h3 className='h1tags'><b>leading to</b> Impact</h3>

            </div>
            <div className='spinlogo'></div>
        </div>
        {/* <Button variant="light" className='clickhere' id="clickhere" onClick={clickHere}>Click Here</Button> */}

        {/* <div className='scrolldown' onClick={ActiveScroll}>
         <span className='scrollh1' >Click Here</span>   
        <div class="boxscroll">
                <span></span>
                <span></span>
          </div>
        </div> */}

         <div className='ourhistory'>
              <span className='historyh1'>
                   <span className='data'>2000 +</span>
                  
                   <i>Projects Done</i>
              </span>
              <span className='historyh1'>
                   <span className='data'>15000 +</span>
                   <i>Creative</i>
              </span>
              <span className='historyh1'>
                   <span className='data'>60 +</span>
                   <i>Satisfied Clients</i>
              </span>
              <span className='historyh1'>
                   <span className='data'>2000 +</span>
                   <i>online Engagement</i>
              </span>
              <span className='historyh1'>
                   <span className='data'>2000 +</span>
                   <i>Client Retention</i>
              </span>
                 
         </div>
        <div className='theboy'></div>
        <div className="city"></div>
        <div className='landscape'>
            <Lottie animationData={LandParticles} loop={true} />
        </div>
        <Stickyfooter/>
       
    </section>

    
    <FloatingWhatsApp className='whatsapp-container' avatar={Avatar}  phoneNumber="+974 7006 1665" accountName="Altairads" allowEsc allowClickAway notification notificationSound/>
</div>
   
   
)}
export default Landingpage;


