import React, { useState, useRef, useCallback}  from 'react'
import Image from 'react-bootstrap/Image';
import GifLogo from '../assets/logo512.png'
import WhitLogo from '../assets/Altair_Logo_White.svg'
import Footerpage from './footer';
import Floatingstars from './starthree';


const newsItems = [{
    key: "**Altairads.com BEST Creative Digital Agency, Experts in Web Design, ",
  },
  {
    key: "Branding & Digital Marketing strategy, Advertising service,",
  },
  {
    key: "Event service & Corporate Gift service AGENCY IN DOHA, QATAR!** ",
  },
  
  // {
  //   key: "Call Us @ (+974) 44 418 617",
  // },
  // {
  //   key: "Whatsapp Us @ (+974) 7006 1665",
  // },
  // {
  //   key: "Mail Us @ info@altairads.com",
  // }

];
  
  const Element = (props) => {
  const {title} = props;
  
  return (
  <div className="element"> <span className='whitelogo'><Image src={WhitLogo} alt="whitelogo"/></span>{title}</div>
  )
  }


function LandFooterpage() {

      const [items, setItems] = useState(newsItems);
    const wrapperRef = useRef();
    const indexRef = useRef();
    
    const handleRefUpdate = useCallback(node => {
    if (node !== null && items.length > 0) {
      indexRef.current = node.firstChild;
      wrapperRef.current = node;
      document.documentElement.style.setProperty('--animationDistance', `${0 - indexRef.current.offsetWidth}px`);
      document.documentElement.style.setProperty('--animationDuration', `${Math.round(indexRef.current.offsetWidth / 33)}s`);
      wrapperRef.current.classList.add('moving');
    }
    }, [items]);
    
    const handleLoop = () => {
    wrapperRef.current.classList.remove('moving');
    wrapperRef.current.style.animation = 'none';
    // const t = wrapperRef.current.offsetHeight; 
    wrapperRef.current.style.animation = null;
    shiftNext([...items]);
    };
    
    const shiftNext = (copy) => {
    const firstitem = copy.shift();
    copy.splice(copy.length, 0, firstitem);
    setItems(copy);
    };
    
    const handleAnimationEnd = () => {
    handleLoop();
    }
    return(
  
            <div className="landingcontact">
                <Floatingstars />
               <div className='section-footpage'>
              
                <div className="h1ticker">
                     <div className="wrapper">
                            <div className="inner" ref={handleRefUpdate} onAnimationEnd={handleAnimationEnd}>
                            {items.map((obj, index) => <Element title={obj.key} key={obj.key+index} />
                            )} 
                            </div>
                           
                     </div>
                     <a className='Explorebutton' href='/contact'>Contact Us Now <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" className="Button_buttonArrow__0W9XI"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg></a>
                </div>
               </div>
                <div className='cont-details'>
                     <div className='_socilamedia'>
                     <div className='cont-details_1'>
                     <div className="gifload"><Image src={GifLogo} alt="Altair_Logo"/></div>
                      <p>We are a team of innovative brains focused to cover the all-round aspects of Advertising and Marketing communications.</p>
                        
                   </div>
                   <div className='cont-details_2'>
                       <div className='mailtag'>
                            <h4>info@altairads.com</h4>
                       </div>
                       <div className='contact'>
                             <h5><b>Call Us:</b>&nbsp;+974 44 418 617</h5>
                             <h6><b>Whatsapp Us:</b>&nbsp;+974 7006 1665</h6>
                       </div>
                       <div className='socoilmedia'>
                             <Footerpage/>
                         </div>
                   </div>
                     </div>
                      <ul className='listPage'>
                      <li className='menuFooter'> <a href="/">Home <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/about">About <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/service">Services <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                                <li className='menuFooter'> <a href="/work">Work <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.69 12.69"><path fill="#fff" stroke-width="0" d="M12.65.31a.504.504 0 00-.46-.31H.5C.22 0 0 .22 0 .5s.22.5.5.5h10.49L.55 11.43c-.2.2-.2.51 0 .71a.485.485 0 00.7 0L11.69 1.71v10.47c0 .28.22.5.5.5s.5-.22.5-.5V.52c0-.07 0-.14-.04-.21z"></path></svg></a> </li>
                               
                             </ul>
                </div>

            </div>

    )
}
export default LandFooterpage;