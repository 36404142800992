import React  from 'react'


  function Footerpage() {
       
  
    
        return (
             <>
             
             
              
              <div className="social-media">
                    <a href="https://twitter.com/altairads" aria-label="twitter" target="_blank" rel="noopener noreferrer">
                        <span className="FadeIn_container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08">
                                <path fill="currentColor"
                                    d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z">
                                </path>
                                <path fill="currentColor"
                                    d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z">
                                </path>
                            </svg></span>
                            </a>
                            <a href="https://www.instagram.com/altair_ads/?hl=en" aria-label="instagram" target="_blank" rel="noopener noreferrer">
                            <span className="FadeIn_container"><svg
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08">
                                <path fill="currentColor"
                                    d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z">
                                </path>
                                <path fill="currentColor"
                                    d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z">
                                </path>
                                <path fill="currentColor"
                                    d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z">
                                </path>
                            </svg></span>
                            </a><a href="https://www.linkedin.com/company/altairadvertising/" aria-label="linkedin" target="_blank"  rel="noopener noreferrer">
                            <span className="FadeIn_container"><svg
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08">
                                <path fill="currentColor"
                                    d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z">
                                </path>
                                <path fill="currentColor"
                                    d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z">
                                </path>
                            </svg></span>
                            </a><a href="https://www.facebook.com/Altairads/" aria-label="facebook" target="_blank" rel="noopener noreferrer">
                            <span className="FadeIn_container"><svg
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08">
                                <path fill="currentColor"
                                    d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z">
                                </path>
                                <path fill="currentColor"
                                    d="M20.74 27.49v-7.11h2.39l.36-2.77h-2.74v-1.77c0-.8.22-1.35 1.37-1.35h1.47v-2.48c-.71-.08-1.43-.11-2.14-.11a3.338 3.338 0 00-3.57 3.09c-.01.19-.01.38 0 .58v2.04h-2.4v2.77h2.4v7.11h2.86z">
                                </path>
                            </svg></span>

                            </a>
                    </div>
  
             
             </>
        );
      }
      
      export default Footerpage;