import React from 'react';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, Stars, TrackballControls} from '@react-three/drei'

function Mesh() {
    return (
        <mesh>
             <fog attach="fog" args={['#272730', 10, 20]} />
            <ambientLight intensity={0.5} />

            <PerspectiveCamera makeDefault position={[12, 12, 16]} fov={75}>
                <pointLight intensity={1} position={[-10, -25, -10]} />
                <spotLight intensity={0.6} position={[30, 30, 50]} angle={0.2} penumbra={1} castShadow />
            </PerspectiveCamera>
            <OrbitControls autoRotate enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2}
                minPolarAngle={Math.PI / 2} />
            <Stars radius={750} depth={40} count={800} factor={14} />
            <TrackballControls />
        </mesh>
    )
}

export default function StarAnimi() {
    return (
        <div className='cavas'>
        <Canvas>
                <Mesh/>
            </Canvas>
        </div>
    
    )
  }