import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from 'swiper/modules';
import heart from '../assets/love.png'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
// Import Swiper styles
import "swiper/css";
// import "../teams.js"

function Teams() {
      let carousel;
      let watchSlidesProgress;
         if(window.innerWidth >= 1200) {
             carousel = '4';
             watchSlidesProgress = 'true'
         } else {
             carousel = 'false'
             watchSlidesProgress  = 'false'
         }

         useGSAP(() => {

            let tl = gsap.timeline({scrollTrigger: {trigger:".contianer-teams",start: "top bottom",end: "bottom top",toggleActions: "restart none none none"}});
   
        tl.to("#team-items .team-item", {
            keyframes: {
              y: [0, 20, -10, 20, 0],
              ease: "none", 
              easeEach: "power2.inOut" 
            },
            rotate: 0,
            ease: "elastic",
            repeat:3,
            repeatDelay: 0.8,
            duration: 5,
            stagger: 0.5
          });

    })
  return (
    <div className='contianer-teams footer'>
            <Container>
            <div className='h5tag'>
            <span className='stickyhead'>
            <h4 className='h5tags'><b>Our</b> TEAMS</h4>
             <h5> Trust in our expert team for exceptional service & solutions.</h5>
            </span>
             <p>
We are a team of innovative brains focused to cover the all-round aspects of Advertising and Marketing communications. With creative solutions and result-driven services, we have placed ourselves among the pioneers of the industry within a short period.</p>
            </div>
                <Row className='middle-element'>
                    <div className="team-items">
                        <div className="row" id="team-items">
                            <Swiper watchSlidesProgress={watchSlidesProgress} slidesPerView={carousel} mousewheel={true} pagination={{ clickable: true,}} modules={[Mousewheel, Pagination]} className="mySwiper">
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo" src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7430.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Wojciech Tokarz</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">Conversion Optimization Specialist</p>
                                        </div>
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <i class="bi bi-linkedin"></i> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7435.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Bartłomiej Ziemba</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">E-mail Marketing Specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7427.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Patrycja Pawłowska</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">Junior E-mail Marketing Specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7482.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Martyna Zembrzuska</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">E-mail marketing specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7430.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Wojciech Tokarz</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">Conversion Optimization Specialist</p>
                                        </div>
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7435.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Bartłomiej Ziemba</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">E-mail Marketing Specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7427.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Patrycja Pawłowska</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">Junior E-mail Marketing Specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-item">
                                        <img alt='teams' className="team-item__photo"
                                            src="https://webimpact.llc/wp-content/uploads/2023/12/D1A7482.jpg" />
                                        <h4 className="team-item__name p1 p1--bold ">Martyna Zembrzuska</h4>
                                        <div className="team-item__position ">
                                            <img alt='teams' src={heart} />
                                            <p className="p3">E-mail marketing specialist</p>
                                        </div>
    
                                        <div className='linked-in'>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="linkedin" aria-label="linkedin">  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.25 40.08"><path fill="currentColor" d="M19.62 40.08C8.8 40.08 0 31.09 0 20.04S8.8 0 19.62 0s19.62 8.99 19.62 20.04-8.8 20.04-19.62 20.04zm0-39.08C9.35 1 1 9.54 1 20.04s8.35 19.04 18.62 19.04 18.62-8.54 18.62-19.04S29.89 1 19.62 1z"></path><path fill="currentColor" d="M28.11 20.34v5.97h-3.46v-5.57c0-1.4-.5-2.35-1.75-2.35-.8 0-1.51.51-1.78 1.27-.09.27-.13.56-.11.84v5.82h-3.46s.05-9.44 0-10.42h3.46v1.48l-.02.03h.02v-.03a3.442 3.442 0 013.12-1.72c2.28 0 3.99 1.49 3.99 4.69zm-14.2-9.46a1.799 1.799 0 10-.04 3.6h.02c.99.09 1.87-.65 1.96-1.64.09-.99-.65-1.87-1.64-1.96h-.29zm-1.75 15.44h3.46V15.91h-3.46v10.41z"></path></svg> </a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.08 40.08"><path fill="currentColor" d="M20.04 40.08C8.99 40.08 0 31.09 0 20.04S8.99 0 20.04 0s20.04 8.99 20.04 20.04-8.99 20.04-20.04 20.04zm0-39.08C9.54 1 1 9.54 1 20.04s8.54 19.04 19.04 19.04 19.04-8.54 19.04-19.04S30.54 1 20.04 1z"></path><path fill="currentColor" d="M10.56 26.59c2.05.21 4.11-.38 5.74-1.64a4.022 4.022 0 01-3.67-2.75c.58.1 1.17.07 1.74-.07a3.944 3.944 0 01-3.1-3.9c.54.28 1.14.44 1.75.46a3.943 3.943 0 01-1.71-2.83c-.11-.84.07-1.69.5-2.42a11.27 11.27 0 008.11 4.11c-.02-.23-.06-.45-.07-.66-.12-1.49.64-2.91 1.94-3.65 1.49-.88 3.39-.67 4.66.51.12.14.31.19.49.13.76-.19 1.49-.48 2.17-.87-.27.87-.82 1.62-1.57 2.14.23-.04.46-.08.69-.13s.48-.12.72-.2.46-.17.68-.25l.04.04c-.31.37-.61.75-.95 1.1-.28.27-.57.53-.87.77-.09.06-.13.16-.12.27.07 2.66-.79 5.26-2.43 7.35a10.487 10.487 0 01-6.62 4.03c-2.03.38-4.12.21-6.06-.51-.73-.26-1.43-.61-2.07-1.04h.01z"></path></svg></a>
                                            <a href="https://www.instagram.com/altair_ads/" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40.08"><path fill="currentColor" d="M19.21 40.08C8.62 40.08 0 31.09 0 20.04S8.62 0 19.21 0s19.21 8.99 19.21 20.04-8.62 20.04-19.21 20.04zm0-39.08C9.17 1 1 9.54 1 20.04s8.17 19.04 18.21 19.04 18.21-8.54 18.21-19.04S29.25 1 19.21 1z"></path><path fill="currentColor" d="M19.48 11.89h3.47a4.275 4.275 0 014.26 3.48c.05.26.08.52.08.78v7.1a4.296 4.296 0 01-3.56 4.19c-.25.04-.5.06-.76.06h-6.99a4.3 4.3 0 01-4.3-4.28v-7.07c0-2.08 1.52-3.86 3.57-4.21.24-.04.49-.06.74-.06h3.5zm-6.64 7.8v3.46c0 .15 0 .3.02.45a3.118 3.118 0 003.12 2.74h7c1.74 0 3.14-1.41 3.14-3.15v-6.97c0-.14 0-.28-.02-.42a3.127 3.127 0 00-3.08-2.75h-7.07a3.122 3.122 0 00-3.11 3.16v3.48z"></path><path fill="currentColor" d="M15.31 19.69c0-2.3 1.87-4.16 4.18-4.16 2.3 0 4.16 1.87 4.16 4.18a4.165 4.165 0 01-8.33-.01zm7.16.01c0-1.65-1.34-2.99-2.99-2.99-1.65 0-2.99 1.34-2.99 2.99 0 1.65 1.34 2.99 2.99 2.99 1.65 0 2.99-1.34 2.99-2.99zM23.8 16.18c-.45 0-.81-.37-.81-.81 0-.45.37-.81.81-.81s.81.37.81.81c0 .44-.35.8-.79.81h-.02z"></path></svg></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
    
                </Row>
            </Container>
    </div>
   
  );
}

export default Teams;