import React from "react";
import { useGSAP } from "@gsap/react";
import gsap from 'gsap'
import { Container } from "react-bootstrap";
import SplitType from 'split-type'
import AlteriaLogo from '../assets/Altairlogo.png'
import Avatar from "../assets/altairads-Logo2024.png";
import Clients from '../Components/clients'
import Teams from '../Components/teams'
import ContactDetails from './Stickyfooter'
import Nav from "../Components/navbar"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function Containerabout() {
  useGSAP(() => {

    let typeSplit = new SplitType('.aboutus h2', {
        types: 'chars',
        tagName: 'span'
        })

        let PtagsSplit = new SplitType('.aboutus p', {
          types: 'words',
          tagName: 'span'
          })
    
                let tl = gsap.timeline({
                    scrollTrigger: {
                      trigger:".aboutus",
                      // trigger element - viewport
                      start: "top bottom",
                      end: "bottom top",
                      toggleActions: "restart none none none"
                    }
                  });
                  tl.from('.aboutus h2 .char', {duration: 2, repeat:-1, repeatDelay:1,  ease: 'power1.out', stagger: { each: 0.1, from: 'start' },  y: '-20%'},.5);
                  tl.from('.aboutus p .word', {
                    y: '100%',
                    opacity: 0,
                    duration: 1,
                    repeat:-1, repeatDelay:6, 
                    stagger: 0.1,
                    ease: 'power1.out',
                  },.5)
          
   })
    
    return(
       <section className="innerContainer aboutContainer">
        <Nav/>
        <a href='/' aria-label="logo" className='alter-logo logofix'></a>
         <div data-scroll-section className='section-contianer panel' id="about">
  <Container>
            <div className="aboutus">
            <h2>Pioneering the </h2>
            <h2>future, <b>today.</b></h2>
             <div className="split-itemx">
              <span className="Alterlogo">
              <img src={AlteriaLogo} alt="Altair" width="140" height="44"/>
              </span>
              <span>
              <p className='ptags'>Hey there! Welcome to Altair - your go-to for all things creative and advertising in Doha, Qatar! Since 2017, We've been shaking up the marketing scene, giving brands like yours a boost with inspiration, innovation and impactful action.
        </p>
              </span>
              <span>
          <p className='ptags'>We fuse design with technology to elevate brands, delivering superior solutions tailored to your success.. From online presence (design & social) to real-world impact (events and marketing activations), we craft experiences that leave a lasting impression. </p>
          </span>
             </div>
            </div>
      </Container>
      </div>
      <div data-scroll-section className='section-contianer panel' id="clients">
      <Clients/>
      </div>
      {/* <div data-scroll-section className='section-contianer panel' id="teams">
      <Teams/>
      </div> */}
      <div data-scroll-section className='section-contianer panel' id="contact">

      <ContactDetails/>
      </div>
      <FloatingWhatsApp className='whatsapp-container' avatar={Avatar}  phoneNumber="+974 7006 1665" accountName="Altairads" allowEsc allowClickAway notification notificationSound/>
       </section>
       
    )
}

export default Containerabout;

