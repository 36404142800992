
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import gsap from 'gsap';
// import { useGSAP } from '@gsap/react';


function OffcanvasNav() {

  return (
    <>
        <Navbar key="false" expand="false" className="nav-menu">
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
            <Navbar.Offcanvas id={`offcanvasNavbar-expand-false`} aria-labelledby={`offcanvasNavbarLabel-expand-false`} placement="end">
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1" variant="tabs" defaultActiveKey="home">
                    <Nav.Link className='menuClick'  href="/">Home</Nav.Link>
                    <Nav.Link className='menuClick' href="/about">About</Nav.Link>
                    <Nav.Link className='menuClick' href="/service" >Services</Nav.Link>
                    <Nav.Link className='menuClick' href="/Work">Work</Nav.Link>
                    {/* <Nav.Link className='menuClick' href="/clients">Clients</Nav.Link> */}
                    {/* <Nav.Link className='menuClick' href="#teams" eventKey="teams">Teams</Nav.Link> */}
                    <Nav.Link className='contact-button menuClick' href="/contact" eventKey="contact">Start your Project <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 15.17" className="buttonArrow"><path d="m17.1,7.58s-.01-.04-.01-.06c.01-.22-.06-.45-.24-.61L9.23.19c-.31-.27-.78-.24-1.06.07-.27.31-.24.78.07,1.06l6.26,5.52H.75c-.41,0-.75.34-.75.75s.34.75.75.75h13.74l-6.26,5.52c-.31.27-.34.75-.07,1.06.15.17.35.25.56.25.18,0,.35-.06.5-.19l7.62-6.72c.18-.16.25-.39.24-.61,0-.02.01-.04.01-.06Z" fill="currentColor"></path></svg></Nav.Link>
                </Nav>              
              </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
  
    </>
  );
}

export default OffcanvasNav;