import React from 'react';
// import { useGSAP } from "@gsap/react";
// import gsap from "gsap";
import { Container } from 'react-bootstrap';
// import	client_0	from	"../assets/Clients/client_000.png"
import	client_1	from	"../assets/Clients/client_001.png"
import	client_2	from	"../assets/Clients/client_002.png"
import	client_4	from	"../assets/Clients/client_004.png"
import	client_6	from	"../assets/Clients/client_006.png"
import	client_8	from	"../assets/Clients/client_008.png"
import	client_10	from	"../assets/Clients/client_010.png"
import	client_12	from	"../assets/Clients/client_012.png"
import	client_14	from	"../assets/Clients/client_014.png"
import	client_16	from	"../assets/Clients/client_016.png"
import	client_18	from	"../assets/Clients/client_018.png"
import	client_20	from	"../assets/Clients/client_020.png"
import	client_22	from	"../assets/Clients/client_022.png"
import	client_24	from	"../assets/Clients/client_024.png"
import	client_25	from	"../assets/Clients/client_025.png"
import	client_27	from	"../assets/Clients/client_027.png"
import	client_29	from	"../assets/Clients/client_029.png"
import	client_31	from	"../assets/Clients/client_031.png"
import	client_34	from	"../assets/Clients/client_034.png"
import	client_35	from	"../assets/Clients/client_035.png"
import	client_37	from	"../assets/Clients/client_037.png"
import	client_39	from	"../assets/Clients/client_039.png"
import	client_40	from	"../assets/Clients/client_040.png"
import	client_41	from	"../assets/Clients/client_041.png"
import	client_42	from	"../assets/Clients/client_042.png"
import	client_43	from	"../assets/Clients/client_043.png"
import	client_44	from	"../assets/Clients/client_044.png"

function Clients() {

const Clents = [
    // {
    //     src:client_0
        
    //   },
    {
        src:client_1
      },
        {
        src:client_2
      },
        {
        src:client_4
      },
        {
        src:client_6
      },
        {
        src:client_8
      },
        {
        src:client_10
      },
        {
        src:client_12
      },
        {
        src:client_14
      },
        {
        src:client_16
      },
        {
        src:client_18
      },
        {
        src:client_20
      },
        {
        src:client_22
      },
        {
        src:client_24
      },
        {
        src:client_25
      },
        {
        src:client_27
      },
        {
        src:client_29
      },
        {
        src:client_31
      },
        {
        src:client_34
      },
        {
        src:client_35
      },
        {
        src:client_37
      },
        {
        src:client_39
        },
        {
          src:client_40
          },
          ,
        {
          src:client_41
          },
          ,
        {
          src:client_42
          },
          ,
        {
          src:client_43
          },
          ,
        {
          src:client_44
          }
      
] 

    // useGSAP(() => {

    //     let tl = gsap.timeline({
    //         scrollTrigger: {
    //           trigger:".contianer-clients",
    //           start: "top bottom",
    //           end: "bottom top",
    //           markers: true,
    //           toggleActions: "restart none none none"
    //         }
    //       });
    //     tl.to(".cz_grid_link", {
    //         keyframes: {
    //           y: [0, 80, -10, 30, 0],
    //           ease: "none", 
    //           easeEach: "power2.inOut" 
    //         },
    //         // rotate: 360,
    //         ease: "elastic",
    //         duration: 5,
    //         stagger: 0.2
    //       });

    // })
  return (
    <section className='contianer-clients footer'>
        
            <Container>
                
            <div className='h5tag'>
            <span className='stickyhead'>
            <h4 className='h5tags'><b>Our</b> PARTNERS</h4>
             <h5> Our Satisfied Clients.</h5>
            </span>
             <p>Our clients’ success stories inspire us. We’re proud to have helped them achieve their goals through our digital solutions.</p>
            </div>
    
            <div class="slick-list">


                    <div class="slick-track">
                    {Clents.map((client, i) => (
                   <a class="cz_grid_link" key={i} href={client.src} data-xtra-lightbox="" tabindex="-1">
                    <img decoding="async" width="300" height="136" src={client.src} class="attachment-medium" alt="" title=""/>
                   <div class="cz_grid_details"><i class="fa fa-search cz_grid_icon"></i></div>
               </a>
             
                
                ))}
                     
                       

                    </div>
                </div>
       
              
            </Container>
    </section>
   
  );
}

export default Clients;