// import logo from './logo.svg';
// import Landingpage from "./Pages/index"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useGSAP } from '@gsap/react';
import Routers from "./Routers";


import './App.css';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP);
function App() {
  return (
    <div className="App">
       <Routers/>
    </div>
  );
}

export default App;
